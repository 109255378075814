import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { DndDropEvent, DropEffect } from 'ngx-drag-drop';
import { Page } from 'src/app/services/feedback/feedback-enum';
import { formTypeCareerManager, formTypeProject,FeedbackTypeEnum } from 'src/app/services/forms/forms-enum';
import swal from 'sweetalert2';
import { field, value } from '../edit-app/global.model';
@Component({
  selector: 'app-edit-app',
  templateUrl: './edit-app.component.html',
  styleUrls: ['./edit-app.component.css']
})
export class EditAppComponent implements OnInit {
  value: value = {
    label: "",
    value: ""
  };

  formTypes: string[] = [];
  success = false;
  public dataNew;
  public show;
  public showComponent: boolean = false;
  public serverName;
  public addSection: boolean = false;
  public inc: number = 0;
  public j: number = 0;
  public counter: number = 0;
  public temp: any[];
  public isHeader: boolean = false;
  public isDescription: boolean = true;
  public submitErrorMessage:string='';
  @Output() public childEvent = new EventEmitter();
  fromPage: any;
  display: string;
  onEditPrevious() {
    if (this.inc > 0)
      this.inc--;
    if (this.inc == 0)
      this.isDescription = true;
  }
  onEditNext() {
    if (this.inc < (this.model.length - 1)) {
      this.inc++;
      this.isDescription = false;
    }
  }
  onClose() {
    this.dialogRef.close();
    this.editDialogRef.close();
  }
  onSave() {
    
    if ( this.checkIfModelIsValid(this.model) ){

          let sourceData = JSON.parse(JSON.stringify(this.model));
          //check description, attributes, name for 0-index and attributes for other sections if other sections attribute is empty then remove those section form model
      
          this.dialogRef.close(sourceData);
          this.editDialogRef.close(sourceData);
          let modelLength = this.model[this.inc].attributes.length;
    }else{
      alert(this.submitErrorMessage)
    }
  }

  checkIfModelIsValid(model){
    let valid:boolean;
    this.submitErrorMessage = ''
    if(model[0].description != '' && model[0].attributes.length != 0 && model[0].name != '' )
      valid =  true
    else{ 
      this.submitErrorMessage = "Please fill all the fields in section "+1;
      valid = false
}
    for(let i = 1; i < model.length; i++){
      
      if(model[i].attributes.length != 0 && model[i].name != '' ){
        this.submitErrorMessage.length==0?valid =  true: valid = false;
        // valid = true
      }
      else {
        this.submitErrorMessage.length==0?this.submitErrorMessage = "Please fill all the fields in section "+(i+1):
                                          this.submitErrorMessage+= " and section "+(i+1);
        valid = false
        
      }
    }

    return valid
  }

  OnInput(value) {
    this.serverName = value;
  }

  OnInputFormType(value) {
    this.serverName = value;
  }
  getNewFormSection() {
    return {
      name: '',
      theme: {
        bgColor: "ffffff",
        textColor: "555555",
        bannerImage: ""
      },
      attributes: []
    }
  }
  onAddSection() {
    this.addSection = true;
    this.isDescription = false;
    this.model.push(this.getNewFormSection())
    this.temp = JSON.parse(JSON.stringify(this.model));
    this.inc++;
  }
  get staticInc() {
    return this.inc;
  }
  fieldModels: Array<field> = [
    {
      "type": "text",
      "icon": "fa-font",
      "label": "Text",
      "description": "Enter your name",
      "placeholder": "Enter your name",
      "className": "form-control",
      "subtype": "text",
      "regex": "",
      "handle": true
    },
    {
      "type": "textarea",
      "icon": "fa-text-width",
      "label": "Textarea"
    },
    {
      "type": "checkbox",
      "required": true,
      "label": "Checkbox",
      "icon": "fa-list",
      "description": "Checkbox",
      "inline": true,
      "values": [
        {
          "label": "Option 1",
          "value": "option-1"
        },
        {
          "label": "Option 2",
          "value": "option-2"
        }
      ]
    },
    {
      "type": "radio",
      "icon": "fa-list-ul",
      "label": "Radio",
      "description": "Radio boxes",
      "values": [
        {
          "label": "Option 1",
          "value": "option-1"
        },
        {
          "label": "Option 2",
          "value": "option-2"
        }
      ]
    },
    {
      "type": "autocomplete",
      "icon": "fa-bars",
      "label": "Select",
      "description": "Select",
      "placeholder": "Select",
      "className": "form-control",
      "values": [
        {
          "label": "Option 1",
          "value": "option-1"
        },
        {
          "label": "Option 2",
          "value": "option-2"
        },
        {
          "label": "Option 3",
          "value": "option-3"
        }
      ]
    }
  ];
  model: any = [{
    name: '',
    description: '',
    theme: {
      bgColor: "ffffff",
      textColor: "555555",
      bannerImage: ""
    },
    attributes: []
  }];
  report = false;
  reports: any = [];
  constructor(
    config: NgbCarouselConfig,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<EditAppComponent>,
    public editDialogRef: MatDialogRef<EditAppComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    config.interval = 10000;
    config.wrap = false;
    config.keyboard = false;
    config.pauseOnHover = false;
  }
  ngOnInit() {

    console.log(this.editDialogRef.componentInstance.data)


    // if (this.editDialogRef.componentInstance.data != null) {
    //   if (Page.Project == this.editDialogRef.componentInstance.data.page) {
    //     for (let formType in formFor) {
    //       console.log(formType)
    //       this.formTypes.push(formType);
    //     }
    //   }
    //   else if (Page.CareerMangerTeam == this.editDialogRef.componentInstance.data.page) {
    //     for (let formType in formTypeCareerManager) {
    //       console.log(formType)
    //       this.formTypes.push(formType);
    //     }
    //   }
      
    // }

    this.dataNew = this.model;
      if (this.editDialogRef.componentInstance.data.property != null)
        if (this.editDialogRef.componentInstance.data.property.length != 0) {
          this.isDescription = true;
          this.model = this.editDialogRef.componentInstance.data.property;
          this.addSection = true;
          this.isHeader = true;
        }
  }
  ngAfterInit() {
  }
  onDragStart(event: DragEvent) {
  }
  onDragEnd(event: DragEvent) {
  }
  onDraggableCopied(event: DragEvent) {
  }
  onDraggableLinked(event: DragEvent) {
  }
  onDragged(item: any, list: any[], effect: DropEffect) {
    if (effect === "move") {
      const index = list.indexOf(item);
      list.splice(index, 1);
    }
  }
  onDragCanceled(event: DragEvent) {
  }
  onDragover(event: DragEvent) {
  }
  onDrop(event: DndDropEvent, list?: any[]) {
    if (list && (event.dropEffect === "copy" || event.dropEffect === "move")) {
      if (event.dropEffect === "copy")
        event.data.name = event.data.type + '-' + new Date().getTime();
      let index = event.index;
      if (typeof index === "undefined") {
        index = list.length;
      }
      list.splice(index, 0, event.data);
    }
  }
  addValue(values) {
    values.push(this.value);
    this.value = { label: "", value: "" };
  }
  removeField(i) {
    swal({
      title: 'Are you sure?',
      text: "Do you want to remove this field?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#00B96F',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove!'
    }).then((result) => {
      if (result.value) {
        this.model[this.inc].attributes.splice(i, 1);
      }
    });
  }
  updateForm() {
    let input = new FormData;
    input.append('id', this.model[this.inc]._id);
    input.append('name', this.model[this.inc].name);
    input.append('description', this.model[this.inc].description);
    input.append('bannerImage', this.model[this.inc].theme.bannerImage);
    input.append('bgColor', this.model[this.inc].theme.bgColor);
    input.append('textColor', this.model[this.inc].theme.textColor);
    input.append('attributes', JSON.stringify(this.model[this.inc].attributes));
  }
  initReport() {
    this.report = true;
    let input = {
      id: this.model[this.inc]._id
    }
  }
  toggleValue(item) {
    item.selected = !item.selected;
  }
  submit() {
    let valid = true;
    let validationArray = JSON.parse(JSON.stringify(this.model[this.inc].attributes));
    if (!valid) {
      return false;
    }
    let input = new FormData;
    input.append('formId', this.model[this.inc]._id);
    input.append('attributes', JSON.stringify(this.model[this.inc].attributes))
  }
}
