import { ANALYZE_FOR_ENTRY_COMPONENTS, Component, OnInit } from '@angular/core';
import { Activity } from 'src/app/services/activity/activity-enum';
import { GoalService } from 'src/app/services/goal/goal.service';
import { GoalType } from 'src/app/services/goalSheet/goal-sheet-enum';
import { InitialAvatarService } from 'src/app/services/initialAvatar/initial-avatar.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-career-manager',
  templateUrl: './career-manager.component.html',
  styleUrls: ['./career-manager.component.css']
})
export class CareerManagerComponent implements OnInit {

  constructor(private goalService: GoalService,
    private userService:UserService,
    private initialAvatarService:InitialAvatarService,
    private userDetatilService: UserDetailsService) { }
  goalSheetActivityType = Activity.VIEW
  feedbackGenerationCMShow = true;
  directReporteesCMShow = false;
  public initialAvatar;
  public name
  ngOnInit(): void {
    this.goalService.resetGoalService()
    this.name = this.userService.processName(this.userDetatilService.getUserDetails())
    this.initialAvatar = this.initialAvatarService.getInitialImage(
      this.userDetatilService.getUserDetails().firstName,
      this.userDetatilService.getUserDetails().lastName
    )
  }

  goalSheetSelf(index) {
    if (index == 0) {
      this.goalService.resetGoalService()


      for (let goalType in GoalType) {
        if (isNaN(Number(goalType))) {
          this.goalService.setGoalServiceSelf(goalType, this.userDetatilService.getUserDetails().id, new Date().getFullYear())
        }
      }
    }
  }

}
