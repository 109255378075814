<div class="card overflow-hidden" style="width:700px; height: auto;">
    <div class="container-fluid" style="padding: 0;">
        <div class="row">
            <div class="col" style="padding: 0;">
                <div class="card-header" style="background-color: #00BFD5; color:white;font-size: larger;">
                    Feedback Form
                    <i class="fas fa-times fa-1x" (click)="onClose()" style="float: right;margin-right: 10px;"></i>
                </div>
                <div class="demo-wrap">
                    <img class="demo-bg" src="assets/feedback-3.png" width="700px" height="450px" alt="">
                    <div class="demo-content">
                        <div class="card-body">
                            <div>
                                <div class="col-1"></div>
                                <div class="col-10">
                                    <div *ngIf="model[inc] && !report" class="padding20 white-bg"
                                        style="align-self: center;">
                                        <app-dynamic-form [parentData]="model[inc]" [disableEdit]="disableEdit"></app-dynamic-form>
                                    </div>
                                </div>
                                <div class="col-1"></div>
                            </div>
                            <div>
                                <div class="row">
                                    <div class="col-1"></div>
                                    <div class="col-10 modal-footer">
                                        <button *ngIf="inc!=0" type="button" class="btn btn-success"
                                            style="margin-right: 10px;width:50px ;" (click)="onEditPrevious()"><i
                                                class="fas fa-arrow-left"></i></button>
                                        <button *ngIf="inc != (this.model.length -1)" type="button" class="btn btn-success"
                                            style="margin-right: 10px;width:50px ;" (click)="onEditNext()"><i
                                                class="fas fa-arrow-right"></i></button>
                                        <button *ngIf="showSubmitButton" type="button" class="btn btn-primary" (click)="onSave()">Submit</button>
                                    </div>
                                    <div class="col-1"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-3"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>