<!-- <div class="row p-2">
    <div class="container-fluid cardContent">
        <div class="row">
            <div class="col-4">
                <div class="alignContent"></div>
                <p class="h3">{{this.feedbackForm.formType}}</p>
                
            </div>
    
            <div class="col-4 col-sm-4 text alignContent justify-content-md-center">
                <p class="h5"><small class="text-muted">{{this.formSectionCount}} Sections</small></p>
                
            </div>
            <div class="col-4 alignContent">
                <button mat-flat-button><mat-icon class="alignExpand" aria-hidden="false" aria-label="Example home icon">edit</mat-icon></button>
                <p class="alignExpandText">edit</p>
            </div>
        </div>
    
    </div>
</div> -->



<div class="container-fluid mt-4">

    <div class="row">
        <div class="col-4"  *ngFor="let feedbackForm of this.formService.forms">

            <div class="card cardContent" style="width: 18rem;">
                <button style="padding:0px" class="editBtn" mat-icon-button (click)="openEditDialog(feedbackForm)" >
                    <mat-icon class="iconEdit">edit</mat-icon>
                </button>
                <button style="padding:0px" class="deleteBtn" mat-icon-button (click)="deleteFeedbackForm(feedbackForm)" >
                    <mat-icon class="iconDelete">delete_outline</mat-icon>
                </button>
                <button mat-flat-button (click)=" openFormTemplateDialog(feedbackForm)">
                    <div class="card-content">
                        <div class="card-body">
                            <img class="img-style" src="assets/forms.png" alt="Card image cap">
                            <p class="h5 card-title">{{feedbackForm.formType}}</p>
                            <!-- <p class="h6 card-text"><small class="text-muted">{{countFormSection(feedbackForm.formTemplate)}} </small></p> -->
                        </div>
                    </div>
                </button>
            </div>

        </div>
    </div>