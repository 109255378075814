import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { User } from 'src/app/models/user';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';
import { MainContentComponent } from '../main-content/main-content.component';
import { AuthoritiesConstants, jobTitles } from 'src/app/services/user/user-enum';
import { ProjectService } from 'src/app/services/project.service';
import { CareerManagerTeamService } from 'src/app/services/careerManager.service';
import { CareerManagerDTO } from 'src/app/dtos/CareerManagerDTO';
import { CareerManagerTeam } from 'src/app/models/careerManagerTeam';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification/notification.service';

@Component({
  selector: 'app-add-employee-card',
  templateUrl: './add-employee-card.component.html',
  styleUrls: ['./add-employee-card.component.css']
})
export class AddEmployeeCardComponent implements OnInit {

  newEmployeeDetails: FormGroup;
  userDto = new UserDTO();
  jobTitles = [];
  careerManagerDto: CareerManagerDTO = new CareerManagerDTO();
  careerManagerTeam: CareerManagerTeam;
  filteredCareerManager: Observable<any[]>;


  constructor(
    public userService: UserService,
    public userDetailService: UserDetailsService,
    public fb: FormBuilder,
    private notificationService:NotificationService,
    public projectService: ProjectService,
    public careerManagerService: CareerManagerTeamService,
    public dialogRef: MatDialogRef<MainContentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: User) {

    this.newEmployeeDetails = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      jobTitle: ['', [Validators.required]],
      department: ['', [Validators.required]],
      email: ['', [Validators.required]],
      mobileNo: [''],
      project: [''],
      careerManager: [''],
      employeeId: ['', [Validators.required]],
      dateOfJoining: [new Date(), [Validators.required]],

    })

    this.filteredCareerManager = this.newEmployeeDetails.get('careerManager').valueChanges.pipe(
      startWith(''),
      map(state => this.filterManager(state))
    )
  }



  ngOnInit(): void {

    if (this.data) {

      this.newEmployeeDetails.get('firstName').setValue(
        this.data.firstName ? this.data.firstName : ''
      )

      this.newEmployeeDetails.get('lastName').setValue(
        this.data.lastName ? this.data.lastName : ''
      )

      this.newEmployeeDetails.get('jobTitle').setValue(
        this.data.jobTitle ? this.data.jobTitle : ''
      )

      this.newEmployeeDetails.get('department').setValue(
        this.data.department ? this.data.department : ''
      )

      this.newEmployeeDetails.get('email').setValue(
        this.data.email ? this.data.email : ''
      )

      this.newEmployeeDetails.get('mobileNo').setValue(
        this.data.mobileNumber ? this.data.mobileNumber : ''
      )

      if (this.data.careerManagerId) {
        this.userService.getUserByID(this.data.careerManagerId).subscribe(res => {
          this.newEmployeeDetails.get('careerManager').setValue(new User(res.body))
        })
      }

      this.newEmployeeDetails.get('employeeId').setValue(
        this.data.employeeId ? this.data.employeeId : ''
      )

      this.newEmployeeDetails.get('dateOfJoining').setValue(
        this.data.dateOfJoining ? this.data.dateOfJoining : new Date()
      )



    }

    console.log(Object.values(jobTitles))
    for (let jobTitle of Object.values(jobTitles)) {
      if (isNaN(Number(jobTitle)))
        this.jobTitles.push(jobTitle)

    }
    this.projectService.getProjects();
    this.userService.getUser()



  }

  onSubmit() {

    if (this.newEmployeeDetails.valid) {
      console.log(this.newEmployeeDetails)

      this.userDto.activated = true;
      this.userDto.authorities = [AuthoritiesConstants.USER]
      this.userDto.createdBy = this.userDetailService.getUserDetails().id
      this.userDto.createdDate = new Date()
      this.userDto.lastModifiedBy = this.userDto.createdBy
      this.userDto.lastModifiedDate = this.userDto.lastModifiedDate
      this.userDto.firstName = this.newEmployeeDetails.get('firstName').value
      this.userDto.lastName = this.newEmployeeDetails.get('lastName').value
      this.userDto.email = this.newEmployeeDetails.get('email').value
      this.userDto.jobTitle = this.newEmployeeDetails.get('jobTitle').value
      this.userDto.login = this.userDto.email
      this.userDto.microsoftId = 'asldkfjasdgnak23rafa9'
      this.userDto.mobileNumber = this.newEmployeeDetails.get('mobileNo').value
      this.userDto.langKey = "en"
      this.userDto.careerManagerId = this.newEmployeeDetails.get('careerManager').value.id;
      this.userDto.department = this.newEmployeeDetails.get('department').value
      this.userDto.employeeId = this.newEmployeeDetails.get('employeeId').value
      this.userDto.dateOfJoining = this.newEmployeeDetails.get('dateOfJoining').value

      if (this.data) {

        if (this.data.id) this.userDto.id = this.data.id
        if (this.data.microsoftId) this.userDto.microsoftId = this.data.microsoftId
        if (this.data.authorities) this.userDto.authorities = this.data.authorities
        this.userService.updateUser(this.userDto).subscribe(res => {
          this.userService.getUser();


          this.dialogRef.close()
          this.notificationService.sucess("User Data Edited")
        })
      } else {
        this.userService.addUser(this.userDto).subscribe(res => {
          console.log(res.body)

          // this.assignCareerManager(res.body,this.newEmployeeDetails.get('careerManager'))

          this.userService.getUser();


          this.dialogRef.close()
          this.notificationService.sucess("New User Created")
        })
      }
    }else{
      this.notificationService.invalidFormData()
    }



  }

  /**
   * 
   * @param data : It will contain data for 2 employee. 1] Data of an employee who is being assigned a career manager and 2] data of an employee who is a career manager
   * 
   * While assiging a career manager 
   * career manager data might already exist and we only need to append the new employee data if the employee data is not present already. In this situation append the new employee to existing career manager data.
   * OR
   * career manager data does not exist so create a new career manager entry into the backend database and assign the employee as a member of the career manager employee list.
   */
  // assignCareerManager(employee,careerManager){
  //   console.log(employee)
  //   console.log(careerManager)

  //   //This to check it the career manager entry already exists. If it exists then add the peer to the list.
  //   this.careerManagerService.checkIfCareerManagerExistsSubscribe(careerManager.value.id).subscribe(res=>{
  //     this.careerManagerTeam = this.careerManagerService.processCareerManagerResposnseBody(res.body)

  //     this.careerManagerTeam.peers.push(employee)

  //     this.careerManagerService.putCareerManagerTeamData(this.careerManagerTeam);

  //     return
  //   });

  //   //We are preparing object for new career manager
  //   this.careerManagerDto.careerManagerId = careerManager.value.id
  //   this.careerManagerDto.capability = 'Career Manager'
  //   this.careerManagerDto.careerManagerName = careerManager.value.firstName + " " + careerManager.value.lastName

  //   this.careerManagerDto.peers.push(employee)

  //   this.careerManagerService.postCareerManagerData(this.careerManagerDto).subscribe(res=>{
  //     //alert("Career manager created " + res.body)
  //   })
  // }

  closeDialog() {
    console.log("close clicked")
    this.dialogRef.close()
  }

  displayFnCareerManager(user): string {
    return user && (user.firstName || user.lastName) ? user.firstName + ' ' + user.lastName : '';
  }

  filterManager(name: string) {
    console.log(name)
    if (typeof name !== 'object')
      return this.userService.users.filter(user =>
        user.firstName.toLowerCase().indexOf(name.toLowerCase()) === 0 ||
        user.lastName.toLowerCase().indexOf(name.toLowerCase()) === 0);
    else
      return
  }

  getJobTitle(item) {
    if (isNaN(Number(item))) {
      return item
    }
  }
}
