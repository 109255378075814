import { Component, OnInit } from '@angular/core';
export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: any;
}

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.css']
})
export class EmployeeComponent implements OnInit {

  constructor() { }

  tiles: Tile[] = [
    {text: '', cols: 4, rows: 1, color: '#dcdcdc'},
    {text: '', cols: 1, rows: 10, color: '#dcdcdc'},
    {text: 'content', cols: 2, rows:10, color: '#dcdcdc'},
    {text: '', cols: 1, rows: 10, color: '#dcdcdc'},

    {text: 'Five', cols: 1, rows: 1, color: '#dcdcdc'},
    {text: 'six', cols: 2, rows: 1, color: '#fff'},
    {text: 'seven', cols: 1, rows: 1, color: '#dcdcdc'},
  ];

  ngOnInit(): void {
  }

}
