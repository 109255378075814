<div class="container">
    <div class="row mt-2">
        <div class="col mt-1">

            <mat-tab-group #tabRef color="primary" (selectedTabChange)="goalSheetSelf(tabRef.selectedIndex)">
                

                <mat-tab label="My Goal Sheet" >
                    <app-goal-sheet class="mt-2" [initialAvatar]="this.initialAvatar"
                        [userName]="this.name"></app-goal-sheet>
                </mat-tab>
                <mat-tab label="Annual Reviews">
                    <app-direct-reportees-cm ></app-direct-reportees-cm>
                </mat-tab>
                <mat-tab label="Feedback Generation">
                    <app-feedback-generation-cm class="mt-2" ></app-feedback-generation-cm>
                </mat-tab>
                
            </mat-tab-group>

        </div>
    </div>
</div>