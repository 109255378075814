import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { InitialAvatarService } from 'src/app/services/initialAvatar/initial-avatar.service';
import { UserService } from 'src/app/services/user/user.service';
import { AddEmployeeCardComponent } from '../add-employee-card/add-employee-card.component';

@Component({
  selector: 'app-employee-card',
  templateUrl: './employee-card.component.html',
  styleUrls: ['./employee-card.component.css']
})
export class EmployeeCardComponent implements OnInit {
  
  @Input() empDetail;
  @Input() initialAvatar;
  
  constructor(public userService:UserService,
              private dialog: MatDialog,
              public initialAvatarService:InitialAvatarService) { }
  department;
  
  ngOnInit(): void {
    
  console.log(this.empDetail)
  }

  deleteEmployee(login){
    console.log(login)
    this.userService.deleteUser(login)
  }

  
  editEmployeeDialog(empData){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "70%";
    dialogConfig.height = "60%";
    dialogConfig.data = empData
    const dialogRef = this.dialog.open(AddEmployeeCardComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
