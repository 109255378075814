<div class="container-fluid cardContent" *ngIf="empDetail.jobTitle!=null">
    <div class="row">
        <div class="col-1 col-sm-1 justify-content-center"><img class="img-style" [src]="this.initialAvatarService.getInitialImage(this.empDetail.firstName,this.empDetail.lastName)"></div>
        <div class="col-3">
            <div class="alignUserName"></div>
            <p class="h3">{{empDetail.firstName + ' ' + empDetail.lastName}}</p>
            <p class="h5"><small class="text-muted">{{empDetail.jobTitle}}</small></p>
            <p><small class="text-muted">{{ empDetail.department }}</small> </p>
        </div>

        <div class="col-6 col-sm-6 text   alignContent align-self-center">
            <p *ngIf="empDetail.employeeId != null ">Employee Id: {{empDetail.employeeId}}</p>
            <p *ngIf="empDetail.employeeId == null ">Employee Id: Not assigned</p>
            <p>Email: {{empDetail.email}}</p>

            <p>Mobile: {{empDetail.mobileNumber}}</p>
            <p>Joined: 4 Years ago</p>
        </div>
        <div class="col-2 alignContent text-center">
            <div class="row">
                <div class="col-12">
                    <button mat-flat-button (click)="editEmployeeDialog(empDetail)">
                        <mat-icon class="alignExpand" aria-hidden="false" aria-label="Example home icon">edit
                        </mat-icon>
                    </button>
                </div>
                <div class="col-12">
                    <p class="alignExpandText">Edit</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <button mat-flat-button (click)="deleteEmployee(empDetail.login)">
                        <mat-icon class="alignExpand" aria-hidden="false" aria-label="Example home icon">delete_outline
                        </mat-icon>
                    </button>
                </div>
                <div class="col-12">
                    <p class="alignExpandText">Delete</p>
                </div>
            </div>
        </div>
    </div>

</div>