import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user/user.service';
import { AddProjectCardComponent } from '../add-project-card/add-project-card.component';

@Component({
  selector: 'app-main-content-project',
  templateUrl: './main-content-project.component.html',
  styleUrls: ['./main-content-project.component.css']
})
export class MainContentProjectComponent implements OnInit {

 
empDetail;
searchText: string;
constructor(public projectService:ProjectService,
           public employeeServie:UserService,
            private dialog: MatDialog) { }


ngOnInit(): void {

  // Main question is why do we need this. If we are loading project why are we giving call to project

//Delete this code after testing. Testing for deployment 11/11/2021. Tejas Kuthe

  //make this pageable
  // this.employeeServie.getUserSubscribe().subscribe(res=>{
  //   //get rest data and conver it to userDTO
  //   this.employeeServie.setUsersDto(res.body);
  //   this.empDetail = this.employeeServie.usersDto;
  // })
}

OnPageClick(event) {
  this.projectService.pageCount = event.pageIndex
  this.projectService.pageCount += 1
  this.projectService.getProjectPagination();
}



onSearchTextTyped(event) {
  console.log(event)
  if (event == "") {
    event = ""
    this.searchText = ""
    this.projectService.projects = [];
    this.projectService.getProjectPagination();
  } else {
    this.projectService.projects = [];
    this.projectService.getProjectBySearch(event);
  }
}

addProjectDialog(){
  const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = true;
  dialogConfig.autoFocus = true;
  dialogConfig.width = "70%";
  dialogConfig.height = "60%";
  // dialogConfig.data = this.project;
  const dialogRef = this.dialog.open(AddProjectCardComponent, dialogConfig);

  dialogRef.afterClosed().subscribe(result => {
  });
}




}


