import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { interval, Subscription } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { AuthGuardService } from 'src/app/services/auth-guard.service';
import { BadgeService } from 'src/app/services/leftMenuBar/badge.service';
import { LoginService } from 'src/app/services/login.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { animateText, onSideNavChange } from '../../animations/animation';
import { SidenavService } from '../../services/sidenav.service';
@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.css'],
  animations: [onSideNavChange,animateText]  
})
export class LeftMenuComponent implements OnInit {

  public sideNavState: boolean = true;
  public linkText: boolean = true;
  timeInterval:Subscription;
  pollingData: any;
  @ViewChild('sidenav') public sidenav: MatSidenav;
  constructor( 
              public badgeService:BadgeService,
              private _sidenavService: SidenavService,
              public loginService: LoginService,public authGuardService: AuthGuardService,
              public userDetail:UserDetailsService,
              public sidenavService:SidenavService) {
                this.badgeService.feedbackBadgeCountObservable.subscribe(res=>{
                  if(res != undefined)
                    console.log(res.body.length)
                    this.badgeService.feedbackBadgeCount = res.body.length
                })
               }

  ngOnInit() {
    
  this.sidenavService.setSidenav(this.sidenav)
  }

  newPendingFeedbackCountPolling(){
    this.pollingData=interval(1500)
    .pipe(
      startWith(0),
      switchMap(() => this.badgeService.getPendingFeedbackCount())
    )
    .subscribe(
        res => {
           this.badgeService.feedbackBadgeCount = res.body.length;
           console.log("Pending feedback count "+res.body.length) 
        },
        error=>{
  
        }
    );
  }

  onSinenavToggle() {
    this.sideNavState = true
    
    setTimeout(() => {
      this.linkText = true;
    }, 200)
    this._sidenavService.sideNavState$.next(this.sideNavState)
  }

}
