import { Component, Input, OnInit } from '@angular/core';
import { timeStamp } from 'console';
import { User } from 'src/app/models/user';
import { Activity } from 'src/app/services/activity/activity-enum';
import { GoalService } from 'src/app/services/goal/goal.service';
import { GoalStatus, GoalType } from 'src/app/services/goalSheet/goal-sheet-enum';
import { InitialAvatarService } from 'src/app/services/initialAvatar/initial-avatar.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-goal-sheet',
  templateUrl: './goal-sheet.component.html',
  styleUrls: ['./goal-sheet.component.css']
})
export class GoalSheetComponent implements OnInit {
  
  @Input() public userId;
  @Input() public year;
  @Input() public activityType;
  @Input() public userName;
  @Input() public initialAvatar;


  public color = "#28a745";
  goalTypeEnumRef = GoalType;
  goalStatusEnumRef = GoalStatus;

  public goalCategory: { [key: string]: string }= {
    BuildingTheBusinessIP:"Building The Business / IP ",
    ClientRelations:"Client Relations",
    Communication:"Communication ",
    CoreValues:"Core Values",
    TeamWorkAndCollaboration:"Team Work And Collaboration",
    TechnicalCapability:"Technical Capability",
    TimeManagement:"Time Management",
    Compliance:"Compliance",
  }

  constructor(public goalService:GoalService,
              public userService:UserService,
              private initialAvatarService:InitialAvatarService,
              public userDetailService:UserDetailsService) { }

  ngOnInit(): void {
    
    if(this.userId == undefined){
      this.userId = this.userDetailService.getUserDetails().id
    }

    if(this.year == undefined){
      this.year = new Date().getFullYear()
    }

    if(this.activityType == undefined){
      this.activityType = Activity.EDIT
    }
    
    this.userService.getUserByID(this.userId).subscribe(res=>{
      let user:User = new User(res.body)

      this.initialAvatarService.setAvatar(user.firstName,user.lastName)
    })
    
    
    
  }

}
