import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { AngularSpinnerComponent } from 'src/app/components/angular-spinner/angular-spinner.component';
import { MainContentProjectComponent } from 'src/app/components/project/main-content-project/main-content-project.component';
import { GoalDTO } from 'src/app/dtos/goalDTO';
import { Goal } from 'src/app/models/goal';
import { Activity } from 'src/app/services/activity/activity-enum';
import { AngularSpinnerService } from 'src/app/services/angular-spinner.service';
import { EmployeeReviewService } from 'src/app/services/employeeReview/employee-review.service';
import { GoalService } from 'src/app/services/goal/goal.service';
import { GoalStatus, GoalStatusLabel, GoalType, Metric } from 'src/app/services/goalSheet/goal-sheet-enum';
import { InitialAvatarService } from 'src/app/services/initialAvatar/initial-avatar.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';
import { threadId } from 'worker_threads';

@Component({
  selector: 'app-add-goal',
  templateUrl: './add-goal.component.html',
  styleUrls: ['./add-goal.component.css']
})
export class AddGoalComponent implements OnInit {
  subGoalForm: FormGroup;
  subGoalFormEdit: FormGroup;
  goalDto: GoalDTO = new GoalDTO();
  constructor(public goalService: GoalService,
    public userService: UserService,
    private notificationService:NotificationService,
    public fb: FormBuilder,
    private spinnerService:SpinnerService,
    private angularSpinnerService:AngularSpinnerService,
    public initialAvatarService: InitialAvatarService,
    private messageService: MessageService,
    private userDetatilService: UserDetailsService,
    public dialogRef: MatDialogRef<MainContentProjectComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {
    this.subGoalForm = this.fb.group({
      description: ['', [Validators.required]],
      subGoal: ['', [Validators.required]],
      initialValue: [0, [Validators.required, Validators.pattern("^[0-9]*$")]],
      targetValue: [100, [Validators.required, Validators.pattern("^[0-9]*$")]],

    })
    this.subGoalFormEdit = this.fb.group({
      goalStatus: ['', [Validators.required]],
      progress: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      initialValue: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      targetValue: ['', [Validators.required, Validators.pattern("^[0-9]*$")]]

    })
  }

  activityRef = Activity
  public goalStatusRef = GoalStatus;
  public goalStatusLabelRef = GoalStatusLabel;
  public year;
  public goalStatusColor = {
    OnTrack: "circleColorGreen",
    NotStarted: "circleColorGrey",
    AtRisk: "circleColorRed",
    NeedsAttention: "circleColorYellow",
    Closed: "circleColocircleColorGreyrGreen"
  }
  ngOnInit(): void {


    if (this.data.goal) {
      this.year = new Date(this.data.goal.endDate).getFullYear()
      this.subGoalFormEdit.get('progress').setValue(this.data.goal.currentValue)
      this.subGoalFormEdit.get('goalStatus').setValue(this.data.goal.goalStatus)
      this.subGoalFormEdit.get('initialValue').setValue(this.data.goal.initialValue)
      this.subGoalFormEdit.get('targetValue').setValue(this.data.goal.targetValue)

    }



  }

  closeDialog() {
    console.log("close clicked")
    this.dialogRef.close()
  }

  onSubmit() {
    if (this.subGoalForm.valid) {
      this.goalDto.targetValue = "100"
      this.goalDto.metric = Metric.Percent
      this.goalDto.title = this.subGoalForm.get('subGoal').value
      //Change this for dynamic goalType
      this.goalDto.goalType = this.data.goalType
      this.goalDto.description = this.subGoalForm.get('description').value
      this.goalDto.userId = this.userDetatilService.getUserDetails().id
      this.goalDto.initialValue = this.subGoalForm.get('initialValue').value
      this.goalDto.currentValue = this.subGoalForm.get('initialValue').value
      this.goalDto.targetValue = this.subGoalForm.get('targetValue').value
      this.goalService.postSubGoal(this.goalDto).subscribe(res => {
        //Change this to get goal by year
        this.goalService.resetOverAllProgress(this.goalDto.goalType)
        this.goalService.resetSubGoalData(this.goalDto.goalType)
        this.goalService.getGoalByGoalTypeUserIdAndYearSubscribe(this.goalDto.goalType, this.goalDto.userId, new Date(this.goalDto.createdDate).getFullYear()).subscribe(res => {
          this.goalService.setGoals(res.body);
          this.angularSpinnerService.stop()
          this.notificationService.sucess('Sub Goal Created')
        });
        this.closeDialog()
        
      })

    } else {
      this.notificationService.invalidFormData()
    }

  }

  onEdit(goal) {
    if (this.subGoalFormEdit.valid) {
      this.goalDto.processDto(goal)

      this.goalDto.currentValue = this.subGoalFormEdit.get('progress').value
      this.goalDto.goalStatus = GoalStatus[this.subGoalFormEdit.get('goalStatus').value]


      this.goalService.updateSubGoal(this.goalDto).subscribe(res => {
        //Change this to get goal by year
        this.goalService.resetOverAllProgress(goal.goalType)
        this.goalService.resetSubGoalData(this.goalDto.goalType)
        this.goalService.getGoalByGoalTypeUserIdAndYearSubscribe(this.goalDto.goalType, this.goalDto.userId, new Date(this.goalDto.createdDate).getFullYear()).subscribe(res => {
          this.goalService.setGoals(res.body);
          this.angularSpinnerService.stop()
          this.notificationService.sucess('Sub Goal Edited')
        });
        this.closeDialog()
        
        
      })

    }else {
      this.notificationService.invalidFormData()
          }
  }

}
