import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Goal } from 'src/app/models/goal';
import { Activity } from 'src/app/services/activity/activity-enum';
import { InitialAvatarService } from 'src/app/services/initialAvatar/initial-avatar.service';
import { UserService } from 'src/app/services/user/user.service';
import { AddGoalComponent } from '../add-goal/add-goal.component';

@Component({
  selector: 'app-sub-goal-tab',
  templateUrl: './sub-goal-tab.component.html',
  styleUrls: ['./sub-goal-tab.component.css']
})
export class SubGoalTabComponent implements OnInit {

  @Input() goal:Goal;
  @Input() goalTypeLabel;
  @Input() activityType;
  name;
  constructor(public initialAvatarService:InitialAvatarService,
              public userService:UserService,
              private dialog: MatDialog) { }


  ngOnInit(): void {
  
    this.goal.lastUpdated
  
    this.name = this.userService.processName(this.goal.user)
  }

  editSubGoalProgress(goalType){
    

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "70%";
    dialogConfig.height = "80%";
    dialogConfig.data = {
      goal:this.goal,
      goalType:goalType,
      goalTypeLabel:this.goalTypeLabel,
      activityType:Activity.EDIT
    }
   
    const dialogRef = this.dialog.open(AddGoalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
