import { Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.css']
})
export class DynamicFormComponent implements OnInit,OnChanges{
  @Input() public parentData;
  @Input() public disableEdit;
  @Input() public dynamicHeading;
  @Input() public dynamicDesc;
  isFilledFlag:boolean;
  constructor(){
  }
  ngOnChanges() {
    console.log(this.parentData)
    console.log("disableEdit = "+this.disableEdit)
    // if(this.parentData != undefined){
    //     for(let item of this.parentData.attributes){
    //       if(item.type=='text'){
    //         item.value!=undefined ? this.isFilledFlag = true : this.isFilledFlag = false;
    //         break
    //       }
    //       else if(item.type=='textarea'){
    //         item.value!=undefined ? this.isFilledFlag = true : this.isFilledFlag = false;
    //         break
    //       }
          
    //       else if(item.type=='autocomplete'){
    //         item.value!=undefined ? this.isFilledFlag = true : this.isFilledFlag = false;
    //         break
    //       }
        
    //       else if (item.type=='checkbox'){
    //         for(let v of item.values){
    //             v.selected == true ? this.isFilledFlag = true : this.isFilledFlag = false;
    //             break
    //         }
    //       }
    //       else if(item.type=='radio'){
    //         item.value != undefined ? this.isFilledFlag = true : this.isFilledFlag = false;
    //             break
    //       }
    //     }
      
    // }
  }
  ngOnInit() {
    console.log(this.parentData)
  }

  toggleValue(item){
    item.selected = !item.selected;
  }

  submit(){
    let valid = true;
    let validationArray = JSON.parse(JSON.stringify(this.parentData));
    if(!valid){
      return false;
    }
    let input = new FormData;
    input.append('formId',this.parentData._id);
    input.append('attributes',JSON.stringify(this.parentData))
  }
}
