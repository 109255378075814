import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CareerManagerDTO } from 'src/app/dtos/CareerManagerDTO';
import { FeedbackDTO } from 'src/app/dtos/feedbackDTO';
import { ProjectDTO } from 'src/app/dtos/ProjectDTO';
import { CareerManagerTeam } from 'src/app/models/careerManagerTeam';
import { CareerManagerTeamService } from 'src/app/services/careerManager.service';
import { Page } from 'src/app/services/feedback/feedback-enum';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { FormsSchemaService } from 'src/app/services/forms/forms-schema.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';
import { FeedbackTypeEnum } from 'src/app/services/forms/forms-enum'
@Component({
  selector: 'app-feedback-generation-dialog',
  templateUrl: './feedback-generation-dialog.component.html',
  styleUrls: ['./feedback-generation-dialog.component.css']
})
export class FeedbackGenerationDialogComponent implements OnInit {
  // myControl = new FormControl();
  // formTypes: string[] = [];
  // forms: Map<string, string>;
  // // get all formType for which forms are created
  // filteredOptions: Observable<string[]>;
  // filteredOptionsFeedbackFor: Observable<string[]>;
  // filteredOptionsFeedbackFrom: Observable<string[]>;
  // feedbackForFormControl = new FormControl();
  // feedbackFromFormControl = new FormControl();
  // feedbackBy = new FormControl();
  // feedbackGenerationFormGroup: FormGroup;
  // dropdownList = [];
  // dropdownFromUserList = [];
  // dropdownToUserList = [];
  // project = new ProjectDTO();
  // careerManager = new CareerManagerDTO();
  careermanagerData;
  careerManagerPeerList = [];
  feedbackTypeEnum = [];
  // public panelOpenState: boolean = false;
  // public showForm: boolean = false;
  // public formTemplateData;
  // public feedBackDTO: FeedbackDTO[];
  // public managerList;
  // public supervisorList;
  // public peerList;
  // public careerMangerList;
  // relativeId;
  // constructor(public messageService: MessageService, public userDetails: UserDetailsService, public feedbackService: FeedbackService, public userService: UserService, private fb: FormBuilder, private formService: FormsSchemaService,
  //   private dialog: MatDialog, config: NgbCarouselConfig, public dialogRef: MatDialogRef<FeedbackGenerationDialogComponent>,
  //   public editDialogRef: MatDialogRef<FeedbackGenerationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
  //   this.feedbackGenerationFormGroup = this.fb.group({
  //     responses: this.fb.array([]),
  //   });
  //   config.interval = 10000;
  //   config.wrap = false;
  //   config.keyboard = false;
  //   config.pauseOnHover = false;
  // }
  multipleFeedbackGenerationArray: FormGroup;
  feedbackGeneration: FormArray;

  constructor(public editDialogRef: MatDialogRef<FeedbackGenerationDialogComponent>,
    public fb: FormBuilder, public formService: FormsSchemaService, public careerManagerService: CareerManagerTeamService,
    public userService: UserService,
    public userDetailService: UserDetailsService,
    public feedbackService: FeedbackService) {

    this.multipleFeedbackGenerationArray = this.fb.group({
      feedbacks: this.fb.array([])
    })

  }

  ngOnInit(): void {

    //create a new form array entry for feedback generation
    this.addFeedbackGeneration();


    //get all form
    this.formService.getAllForms()

    //Get all company data
    this.userService.getUser()

    //Get all direct reportees of logged in user
    this.userService.getAllDirectReportees(this.userDetailService.getUserDetails().id)


    for (let feedbackType in FeedbackTypeEnum) {
      if (isNaN(Number(feedbackType))) {
        this.feedbackTypeEnum.push(feedbackType);
      }
    }
  

  }

  feedbacks(): FormArray {
    return this.multipleFeedbackGenerationArray.get('feedbacks') as FormArray;
  }


  addFeedbackGeneration() {
    this.feedbackGeneration = this.multipleFeedbackGenerationArray.get('feedbacks') as FormArray;
    this.feedbackGeneration.push(this.createFeedbackGeneration())
  }

  createFeedbackGeneration() {
    return this.fb.group({

      formTemplate: ['', [Validators.required]],
      feedbackType: ['', [Validators.required]],
      directReportee: ['', [Validators.required]],
      employees: ['', [Validators.required]],

    })
  }

  removeFeedback(i: number) {
    this.feedbacks().removeAt(i);
  }


  processName(user) {
    let name;
    if (user && user.firstName)
      name = user.firstName

    if (user && user.lastName) {
      name ? name += " " + user.lastName : name = user.lastName;
    }

    return name;
  }

  toggle(expanded) {
    expanded = !expanded;
  }


  displayFn(object) {
    let result = "";
    if (object instanceof Object) {
      return object.firstName + " " + object.lastName;
    }
    else {
      return object;
    }
  }

  


  /**
   * form:['',[Validators.required]],
      directReportee:['',[Validators.required]],
      employees:['',[Validators.required]],
   */
  onSubmit() {
    if (this.multipleFeedbackGenerationArray.valid) {
      for (let feedback of this.multipleFeedbackGenerationArray.value.feedbacks) {
        let feedBackDTO = new FeedbackDTO();

        if (feedback) {

          feedBackDTO.careerManagerId = this.userDetailService.getUserDetails().id;
          
          feedBackDTO.createdById = this.userDetailService.getUserDetails().id;
          feedBackDTO.updatedById = feedBackDTO.createdById


          feedBackDTO.userReviewFromId = feedback.employees.id;
          feedBackDTO.userReviewToId = feedback.directReportee.id;

          feedBackDTO.formTemplate = JSON.stringify(feedback.formTemplate);
          feedBackDTO.formTemplateType = feedback.formTemplate.formType;

          feedBackDTO.feedbackType = feedback.feedbackType


          this.feedbackService.addFeedbackSubscribe(feedBackDTO).subscribe(res => {
            this.feedbackService.getFeedbackByCareerManagerIdSubscribe(this.userDetailService.getUserDetails().id).subscribe(res => {
              this.feedbackService.setFeedback(res.body)

            })
          });

        }

      }

      this.editDialogRef.close();

    }
    //this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Feedbacks Generated Successfully' });
  }
  onClose() {
    this.editDialogRef.close();
  }
  // onFormTypeSelect(formType, index) {
  //   let type = formType.toLowerCase().split("to")
  //   this.dropdownFromUserList = [];
  //   this.dropdownToUserList = [];
  //   type[0].toLowerCase() == 'manager' ? this.dropdownFromUserList.push(this.managerList) :
  //   type[0].toLowerCase() == 'supervisor' ? this.dropdownFromUserList = this.supervisorList :
  //   type[0].toLowerCase() == 'careermanager' ? this.dropdownFromUserList.push(this.careerMangerList) :
  //       this.dropdownFromUserList = this.peerList;
  //   type[1].toLowerCase() == 'manager' ? this.dropdownToUserList.push(this.managerList) :
  //   type[1].toLowerCase() == 'supervisor' ? this.dropdownToUserList = this.supervisorList :
  //   type[1].toLowerCase() == 'careermanager' ? this.dropdownToUserList.push(this.careerMangerList) :
  //       this.dropdownToUserList = this.peerList;
  //   this.showForm = !this.showForm;
  //   this.filteredOptionsFeedbackFrom = this.feedbackFromFormControl.valueChanges.pipe(
  //     startWith(''),
  //     map(value => this._filterFeedbackFrom(value)
  //     )
  //   );
  //   this.filteredOptionsFeedbackFor = this.feedbackForFormControl.valueChanges.pipe(
  //     startWith(''),
  //     map(value => this._filterFeedbackFor(value)
  //     )
  //   );

  //   if (Page.Project == this.editDialogRef.componentInstance.data.page) {
  //     this.formService.getFormByProjectIdAndFormTypeSubscribe(this.relativeId, formType).subscribe(res => {
  //       this.formTemplateData = res.body;
  //       this.responses().at(index).get('formTemplate').setValue(res.body);
  //     })
  //   }
  //   else if(Page.CareerMangerTeam == this.editDialogRef.componentInstance.data.page){

  //     this.formService.getFormByCareerManagerTeamIdAndFormTypeSubscribe(this.relativeId,formType).subscribe(res =>{
  //       this.formTemplateData = res.body;
  //       this.responses().at(index).get('formTemplate').setValue(res.body);
  //     })
  //   }

  // }


}

function setFormType(id: String) {
  //this.formService.get
}