<!-- <div class="row" style="padding:30px;" [style.backgroundColor]="model.theme.bgColor"
[style.color]="model.theme.textColor"> -->
<!-- <div class="center">
<mat-card style="width: 470px;"> -->
<div class="col-12">
  <h2>{{parentData.name}}</h2>
</div>
<div class="col-12">
  <p>{{parentData.description}}</p>
</div>

  <form class="ftheme">
    <div class="col-12">
      <div *ngFor="let item of parentData.attributes" class="form-group">
        <div *ngIf="item.type=='text'">
          <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
          <input type="text" class="form-control" id="{{item.name}}" placeholder="{{item.placeholder}}" [disabled]="disableEdit"
            [(ngModel)]="item.value" [value] ="item.value" [required]="item.required" [ngModelOptions]="{standalone: true}" autocomplete="off">
        </div>
        <div *ngIf="item.type=='textarea'">
          <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
          <textarea class="form-control" id="{{item.name}}" placeholder="{{item.placeholder}}" [(ngModel)]="item.value"
            [ngModelOptions]="{standalone: true}" autocomplete="off" [disabled]="disableEdit">
            </textarea>
        </div>
        <div *ngIf="item.type=='paragraph'">
          <p class="form-text">{{item.placeholder}}</p>
        </div>
        <div *ngIf="item.type=='autocomplete'">
          <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
          <select class="form-control" id="{{item.name}}" [(ngModel)]="item.value" [ngModelOptions]="{standalone: true}" [disabled]="disableEdit">
            <option *ngFor="let v of item.values" [value]="v.value">{{v.label}}</option>
          </select>
          <!-- <small class="form-text text-muted">{{item.description}}</small> -->
        </div>
        <div *ngIf="item.type=='checkbox'">
          <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
          <div *ngFor="let v of item.values" class="inline-form-group">
            <label class="cust-check-bx">
              <input type="checkbox" [checked]="v.selected" [value]="v.value" [disabled]="disableEdit" name="{{item.name}}" (click)="toggleValue(v)"> {{v.label}}
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
        <div *ngIf="item.type=='radio'">
          <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
          <div *ngFor="let v of item.values" class="inline-form-group">
            <label class="cust-check-bx">
              <input type="radio" [value]="v.value" name="{{item.name}}" [(ngModel)]="item.value" [disabled]="disableEdit"
                [ngModelOptions]="{standalone: true}" [disabled]="disableEdit"> {{v.label}}
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
        <div *ngIf="item.type=='button'" class="btn-cont">
          <input type="{{item.subtype}}" (click)="submit()" value="{{item.label}}" class="btn btn-primary"
            id="{{item.name}}">
        </div>
      </div>
  
    </div>
  </form>
<!-- </mat-card>  
</div> -->