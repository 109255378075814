import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CareerManagerComponent } from './components/career-manager/career-manager.component';

import { EditAppComponent } from './components/edit-app/edit-app.component';
import { EmployeeComponent } from './components/employee/employee.component';
import { EvaluationConfigurationComponent } from './components/evaluation-configuration/evaluation-configuration.component';
import { FeedbackFormGenerationComponent } from './components/feedback-form-generation/feedback-form-generation.component';

import { FeedbackGenerationComponent } from './components/feedback-generation/feedback-generation.component';
import { ProjectAccordianComponent } from './components/feedback-generation/project-accordian/project-accordian.component';
import { FeedbackTabsComponent } from './components/feedback-submission/feedback-tabs/feedback-tabs.component';
import { LoginComponent } from './components/login/login.component';
import { TestParentComponent } from './components/OpenModal/test-parent/test-parent.component';
import { ProjectDetailViewComponent } from './components/project/project-detail-view/project-detail-view.component';
import { ProjectComponent } from './components/project/project.component';
import { Employee } from './models/employee';
import { AuthGuardService } from './services/auth-guard.service';

// const routes: Routes = [
//   {path: '', component: EmployeesComponent, canActivate: [AuthGuardService]},
//   {path: 'employees', component: EmployeesComponent, canActivate: [AuthGuardService]},
//   {path: 'projects', component: ProjectComponent, canActivate: [AuthGuardService]},
//   {path: 'clients', component: ClientComponent, canActivate: [AuthGuardService]},
//   {path: 'feedback', component: FeedbacksComponent, canActivate: [AuthGuardService]},
//   {path: 'track-feedback', component: TrackFeedbackComponent, canActivate: [AuthGuardService]},
//   {path: 'login', component: LoginComponent},
// ];

const routes: Routes = [
  {path: '', component: LoginComponent},
  {path: 'test', component: TestParentComponent},
  {path: 'projects', component: ProjectComponent,canActivate: [AuthGuardService]},
  {path: 'edit', component: EditAppComponent },
  // {path: 'login', component: LoginComponent},
  {path: 'feedback-forms/:id', component: TestParentComponent,canActivate: [AuthGuardService] },
  {path: 'feedback-generation', component:FeedbackGenerationComponent ,canActivate: [AuthGuardService] },
  {path: 'feedback-generation-accordian', component:ProjectAccordianComponent ,canActivate: [AuthGuardService] },
  {path: 'feedback-submission', component:FeedbackTabsComponent ,canActivate: [AuthGuardService] },
  {path: 'employee', component:EmployeeComponent ,canActivate: [AuthGuardService] },
  {path: 'project', component:ProjectComponent ,canActivate: [AuthGuardService] },
  {path: 'projectDetailView/:id', component:ProjectDetailViewComponent ,canActivate: [AuthGuardService] },
  {path: 'careerManager', component:CareerManagerComponent ,canActivate: [AuthGuardService] },
  {path: 'formGeneration', component:FeedbackFormGenerationComponent ,canActivate: [AuthGuardService] },
  {path: 'evaluationConfig', component:EvaluationConfigurationComponent ,canActivate: [AuthGuardService] },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { } 
