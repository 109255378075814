<div class="login-bg">
</div>
<div class="container-fluid text-center login-form">
    <div class="row align-items-center">
        <div class="col-12">
            <h4 style="    color: #333;
            font-size: large;
            font-weight: 400;">Sign in to your Account </h4>
            <!-- <form [formGroup]="loginPasswordFormGroup" (ngSubmit)="signIn()" -->

            <form [formGroup]="loginPasswordFormGroup" (ngSubmit)="onClickLoginGetToken()"
                class="row">
                <div class="col-12">
                    <mat-form-field class="full-width">
                        <mat-label>Email</mat-label>
                        <input matInput type="text" formControlName="email" autocomplete="off" />
                        <mat-error
                            *ngIf="!loginPasswordFormGroup.controls['email'].valid && loginPasswordFormGroup.controls['email'].touched">
                            Email is required
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <mat-form-field class="full-width">
                        <mat-label>Password</mat-label>
                        <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" autocomplete="off">
                        <button mat-icon-button matSuffix type="button" (click)="hide = !hide"
                            [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                        <mat-error
                            *ngIf="!loginPasswordFormGroup.controls['password'].valid && loginPasswordFormGroup.controls['password'].touched">
                            Password is required
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 pt-4">
                    <button mat-raised-button color="primary" type="submit" class="login-sign-up-button">
                        Sign In
                    </button>
                   
                </div>
            </form>
            <div class="col-12 pt-4">
                <button mat-raised-button  class="ml-4" (click)="signIn()" class="login-sign-up-button">
                    Sign In with Microsoft
                </button>
            </div>
        </div>
    </div>
</div>

<!-- <p-toast></p-toast> -->
<app-spinner></app-spinner>