export enum formTypeProject{
    ManagerToSupervisor='Manager To Supervisor', 
    SupervisorToManager = 'Supervisor To Manager', 
    ManagerToPeer = 'Manager To Peer', 
    PeerToManager = 'Peer To Manager', 
    SupervisorToPeer='Supervisor To Peer', 
    PeerToSupervisor ='Peer To Supervisor',
    ClientFeedback ='Client Feedback', 
}

export enum formTypeCareerManager{
    CareerManagerToPeer = "Career Manager To Peer",
    PeerToCareerManager = "Peer To Career Manager"
}

export enum FeedbackTypeEnum{
    
    careerManager = "Career Manager",
    supervisor = "Supervisor",
    peer = "Peer",
    client = "Client",
    selfEvaluation = "Self Evaluation"
    

}


// Consider adding new form type
// project feedback form might have form type adding facility