import { Component, Input, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthGuardService } from 'src/app/services/auth-guard.service';
import { LoginService } from 'src/app/services/login.service';
import { UserDetailsService } from 'src/app/services/user-details.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @Input() sidenav: MatSidenav

  constructor(public loginService: LoginService,public userDetail:UserDetailsService,public authGuardService:AuthGuardService) { }

  ngOnInit() {
    //this.sidenav.close();
  }
}
