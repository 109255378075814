import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EmployeeReviewUrlsService {


  private readonly employeeReviewBaseApi = '/api/employee-reviews';
  private readonly careerManagerId = 'careerManagerId'
  
  constructor() { }

  getBaseUrl(){
    return this.employeeReviewBaseApi;
  }

  getByFieldUrl(field){
    return this.employeeReviewBaseApi + this.getByField(field)
  }

  getByParamUrl(params){
    let queryParamStr = '?'
    let i = 0
    Object.keys(params).forEach(function(key) {
      
      queryParamStr += (key + "=" +params[key])
      
      i++
      if (Object.keys(params).length > i)
        queryParamStr+='&'

     
     
    })
    
    return this.employeeReviewBaseApi + queryParamStr
  }

  private getByField(field){
    return '/'+field;
  }
}
