<div class="sidenav_container" #sidenav [@onSideNavChange]="sideNavState ? 'open' : 'close'">
    <div fxLayout="column" fxLayoutGap="10px" style="height: 100%;">
  
      <div class="user_menu">
        <mat-nav-list>
          <a mat-list-item>
            <img class="jim" src="assets/fg-logo.png" alt="">
            <span *ngIf="this.userDetail.getUserDetails()" [@animateText]="linkText ? 'show' : 'hide'" class="pl-2">{{ this.userDetail.getUserDetails().firstName }} {{ this.userDetail.getUserDetails().lastName }}</span>
          </a>
        </mat-nav-list>
        <mat-divider></mat-divider>
      </div>
  
      <div>
        <mat-nav-list>
          <a mat-list-item *ngFor="let page of this.loginService.pages" [matTooltip]="page.tooltip" [routerLink]="page?.link" [ngStyle]="{'display': page.display == false ? 'none' : 'block' } " routerLinkActive="active-list-item">
            <mat-icon *ngIf="page?.name != 'My Tasks'" style="padding-right:5px;">{{page?.icon}}</mat-icon>
            <mat-icon *ngIf="page?.name == 'My Tasks'" [matBadge]="this.badgeService.feedbackBadgeCount>0?this.badgeService.feedbackBadgeCount:''" matBadgeColor="warn" style="padding-right:5px;">{{page?.icon}}</mat-icon>
            <span [@animateText]="linkText ? 'show' : 'hide'" class="pl-2">{{ page?.name }} </span>
            
          </a>
         
        </mat-nav-list>
      </div>
    </div>
  
    <span class="spacer"></span>
    <div fxLayout="row" fxLayoutAlign="end center" style="padding: 0px 10px;">
      <!-- <button mat-icon-button (click)="onSinenavToggle()">
        <mat-icon *ngIf="sideNavState">arrow_left</mat-icon>
        <mat-icon *ngIf="!sideNavState">arrow_right</mat-icon>
      </button> -->
    </div>
  </div>