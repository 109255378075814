<!-- <mat-grid-list cols="4" rowHeight="50">
    <mat-grid-tile
        *ngFor="let tile of tiles"
        [colspan]="tile.cols"
        [rowspan]="tile.rows"
        [style.background]="tile.color">

      <app-assign-career-manager *ngIf="tile.text == 'content'"></app-assign-career-manager>
    </mat-grid-tile>
  </mat-grid-list> -->

  <div class="container content">
      <div class="row topRow"></div>
      <div class="row mainContent">

        <div class="col-1 mainContentColSide"></div>
        <div class="col-10 mainContentColCenter">
            <app-main-content></app-main-content>
        </div>
        <div class="col-1 mainContentColSide"></div>

      </div>
      <div class="row topRow"></div>
  </div>