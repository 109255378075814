import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { InitialAvatarService } from 'src/app/services/initialAvatar/initial-avatar.service';
import { UserService } from 'src/app/services/user/user.service';
import { AddEmployeeCardComponent } from '../add-employee-card/add-employee-card.component';

@Component({
  selector: 'app-main-content',
  templateUrl: './main-content.component.html',
  styleUrls: ['./main-content.component.css']
})
export class MainContentComponent implements OnInit {

  empDetail;
  searchText: string;
  constructor(public employeeServie: UserService,
    public initialAvatarService: InitialAvatarService,
    private dialog: MatDialog) { }


  ngOnInit(): void {
    //make this pageable
    this.employeeServie.getUserSubscribe().subscribe(res => {
      //get rest data and conver it to userDTO
      this.employeeServie.setUsersDto(res.body);
      this.empDetail = this.employeeServie.usersDto;
    })
  }



  onSearchTextTyped(event) {
    console.log(event)
    if (event == "") {
      event = ""
      this.searchText = ""
      this.employeeServie.usersDto = [];
      this.employeeServie.getUser();
    } else {
      this.employeeServie.usersDto = [];
      this.employeeServie.searchUser(event);
      this.empDetail = this.employeeServie.usersDto;
    }
  }

  addEmployeeDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "70%";
    dialogConfig.height = "60%";
    // dialogConfig.data = this.project;
    const dialogRef = this.dialog.open(AddEmployeeCardComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
    });
  }


}
