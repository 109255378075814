import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ProjectDTO } from 'src/app/dtos/ProjectDTO';
import { Client } from 'src/app/models/client';
import { Image } from 'src/app/models/image';
import { Project } from 'src/app/models/project';
import { User } from 'src/app/models/user';
import { ClientService } from 'src/app/services/client/client.service';
import { ImageService } from 'src/app/services/Images/image.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';
import { MainContentProjectComponent } from '../main-content-project/main-content-project.component';

@Component({
  selector: 'app-add-project-card',
  templateUrl: './add-project-card.component.html',
  styleUrls: ['./add-project-card.component.css']
})
export class AddProjectCardComponent implements OnInit {
  searchText: string;
  filteredClient: Observable<any[]>;
  filteredManager: Observable<any[]>;
  filteredSupervisors: Observable<any[]>;
  filteredPeer: Observable<any[]>;
  @ViewChild('search') searchTextBox: ElementRef;


  searchTextboxControl = new FormControl();


  clients: any = []
  projectLogo = "assets/download.png";

  constructor(
    public projectService: ProjectService,
    public imageService:ImageService,
    public userService: UserService,
    public clientService: ClientService,
    public userDetailService: UserDetailsService,
    private notificationService:NotificationService,
    public fb: FormBuilder,
    public dialogRef: MatDialogRef<MainContentProjectComponent>,
    @Inject(MAT_DIALOG_DATA) public project: Project) {

    this.projectForm = this.fb.group({

      client: ['', [Validators.required]],
      projectName: ['', [Validators.required]],
      projectCode: ['', [Validators.required]],
      projectDescription: ['', [Validators.required]],
      manager: ['', [Validators.required]],
      supervisor: ['', [Validators.required]],
      peer: ['', [Validators.required]],
      department: ['', [Validators.required]]
    })


    this.filteredClient = this.projectForm.get('client').valueChanges.pipe(
      startWith(''),
      map(state => this.filterClients(state))
    )

    this.filteredManager = this.projectForm.get('manager').valueChanges.pipe(
      startWith(''),
      map(state => this.filterManager(state))
    )

    this.filteredSupervisors = this.projectForm.get('supervisor').valueChanges.pipe(
      startWith(''),
      map(state => this.filterManager(state))
    )

    this.filteredPeer = this.searchTextboxControl.valueChanges
      .pipe(
        startWith<string>(''),
        map(name => this.filterManager(name))
      );
  }

  private _filterPeers(name: string): String[] {
    console.log(name)
    if (typeof name !== 'object')
      return this.userService.users.filter(user =>
        user.firstName.toLowerCase().indexOf(name.toLowerCase()) === 0 ||
        user.lastName.toLowerCase().indexOf(name.toLowerCase()) === 0);
    else
      return
  }

  clearSearch(event) {
    event.stopPropagation();
    this.searchTextboxControl.patchValue('');
  }

  openedChange(e) {
    // Set search textbox value as empty while opening selectbox 
    this.searchTextboxControl.patchValue('');
    // Focus to search textbox while clicking on selectbox
    if (e == true) {
      this.searchTextBox.nativeElement.focus();
    }
  }

  // filteredOptionEmployee(formControl:string){
  //   return this.projectForm.get(formControl).valueChanges.pipe(
  //     startWith(''),
  //     map(state => this.filterManager(state) )
  //   )
  // }

  projectForm: FormGroup;
  dropdownSettings: IDropdownSettings;

  projectDto: ProjectDTO = new ProjectDTO();

  ngOnInit(): void {
    

    this.clientService.getClientsSubscribe().subscribe(res => {
      this.clients = res.body
    })

    this.userService.getUserSubscribe().subscribe(res=>{
      this.userService.setUsers(res.body);
      this.userService.setUsersDto(res.body);
      this.filteredPeer = this.searchTextboxControl.valueChanges
      .pipe(
        startWith<string>(''),
        map(name => this.filterManager(name))
      );
    });

    if (this.project) {

      if (this.project.clientId) {
        this.clientService.getClientByIdSubscribe(this.project.clientId).subscribe(res => {
          this.projectForm.get('client').setValue(res.body)
        })
      }

      if (this.project.projectName) this.projectForm.get('projectName').setValue(this.project.projectName)
      if (this.project.projectCode) this.projectForm.get('projectCode').setValue(this.project.projectCode)
      if (this.project.projectDescription) this.projectForm.get('projectDescription').setValue(this.project.projectDescription)
      if (this.project.department) this.projectForm.get('department').setValue(this.project.department)
      if (this.project.managerId) {
        this.userService.getUserByID(this.project.managerId).subscribe(res => {
          this.projectForm.get('manager').setValue(new User(res.body))

        })
      }

      if (this.project.supervisors[0].id) {
        this.userService.getUserByID(this.project.supervisors[0].id).subscribe(res => {
          this.projectForm.get('supervisor').setValue(new User(res.body))
          console.log(this.projectForm.get('supervisor'))
        })
      }

     
    }

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'email',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 6,
      allowSearchFilter: true,
      enableCheckAll: false,
      maxHeight: 70
    };
  }


  filterClients(name: string) {
    console.log(name)
    if (typeof name !== 'object')
      return this.clients.filter(client =>
        client.clientName.toLowerCase().indexOf(name.toLowerCase()) === 0);
    else
      return
  }

  filterManager(name: string) {
    console.log(name)
    if (typeof name !== 'object')
      return this.userService.users.filter(user =>
        user.firstName.toLowerCase().indexOf(name.toLowerCase()) === 0 ||
        user.lastName.toLowerCase().indexOf(name.toLowerCase()) === 0);
    else
      return
  }

  displayFnManager(user): string {
    return user && (user.firstName || user.lastName) ? user.firstName + ' ' + user.lastName : '';
  }

  displayFnClient(client) {
    console.log(client)
    if (client && (client.clientName)) {
      //this.setProjectLogo(client.imageId)
      return client.clientName
    }
    else
      //this.setDefaultProjectLogo()
      return '';
  }

  clientSelected(client) {
    console.log(client)
    this.setProjectLogo(client.imageId)
  }

  setProjectLogo(imageId) {
    this.projectLogo = this.imageService.getImage(imageId)
  }

  setDefaultProjectLogo() {
    this.projectLogo = "assets/download.png";
  }


  onSubmit() {

    console.log(this.projectForm)

    if (this.projectForm.valid) {

      this.projectDto.clientId = this.projectForm.get('client').value.id;
      this.projectDto.dateCreated = new Date();
      this.projectDto.managerId = this.projectForm.get('manager').value.id

      this.projectDto.supervisors.push(this.projectForm.get('supervisor').value)

      for (let user of this.projectForm.get('peer').value)
        this.projectDto.users.push(user)
      this.projectDto.projectName = this.projectForm.get('projectName').value
      this.projectDto.projectCode = this.projectForm.get('projectCode').value
      this.projectDto.department = this.projectForm.get('department').value
      this.projectDto.projectDescription = this.projectForm.get('projectDescription').value


      if (this.project) {
        this.projectDto.id = this.project.id;
        this.projectService.updateProjectSubscribe(this.projectDto).subscribe(res => {
          this.projectService.getProjectPagination()
        })



      } else {
        this.projectService.postProjectData(this.projectDto).subscribe(res => {
          this.projectService.getProjectPagination()
          //alert("Project created Successfully")
        })
      }

      console.log("Submit clicked")
      this.dialogRef.close()
      this.notificationService.sucess("Project Created")
    }
    else {
      this.notificationService.invalidFormData()
    }
  }

  closeDialog() {
    console.log("close clicked")
    this.dialogRef.close()
  }



  onItemSelect(e) {

  }

  onSelectAll(e) {

  }


}
