import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FeedbackUrlService {


  private readonly feedbackBaseApi = '/api/feedbacks';
  private readonly user = '/user';
  private readonly careerManager = '/careerManager'
  private readonly careerManagerId_param = 'careerManagerId'
  private readonly userFrom_param = 'userReviewFrom'
  private readonly feedbackSubmitStatus_param = 'feedbackSubmitStatus'
  private readonly readStatus_param = 'readStatus'


 
  constructor() { 
  }

  getBaseUrl(){
    return this.feedbackBaseApi;
  }

  //This will be used for get by id and delete by id
  getByFieldUrl(field){
    return this.feedbackBaseApi+ this.getByField(field)
  }

  private getByField(field){
    return '/'+field;
  }

  getFeedBackByUserIdAndStatusUrl(userFrom,readStatus,feedbackSubmitStatus){
    return this.feedbackBaseApi+this.user
            +'?'+this.feedbackSubmitStatus_param+'='+feedbackSubmitStatus
            +'&'+this.readStatus_param+'='+readStatus
            +'&'+this.userFrom_param+'='+userFrom

  }

  getCareerManagerUrl(careerManagerId){
    return this.feedbackBaseApi + this.careerManager
        +   '?' + this.careerManagerId_param + '=' +careerManagerId
  }

  
}
