<div>
  <app-header></app-header>

  <mat-sidenav-container class="sidenav-container">

    <!-- Left menu -->
    <mat-sidenav #leftSidenav mode="side" opened>
      <app-left-menu></app-left-menu>
    </mat-sidenav>

    <!-- Main content -->
    <mat-sidenav-content [@onMainContentChange]="onSideNavChange ? 'open': 'close'">
      <div class="main_content">
        <!-- <div *ngIf="this.loader" class="overlay">
          <div class="ng-loader">
            <mat-spinner mode="indeterminate"></mat-spinner>
          </div>
          
        </div>
        <div> -->
          
          <router-outlet></router-outlet>
        <!-- </div> -->
      </div>
      
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
<p-toast></p-toast>