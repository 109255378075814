import { Injectable } from '@angular/core';
import { EmployeeReview } from 'src/app/models/employeeReview';
import { EmployeeReviewUrlsService } from '../restAPI/employeeReviewURLs/employee-review-urls.service';
import { RestAPIService } from '../restAPI/rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeReviewService {
  public employeeReviewList: EmployeeReview[];

  constructor(private restApiService: RestAPIService,
    private employeeReviewUrl: EmployeeReviewUrlsService) { }

  getEmployeeReviewByCareerManagerIdSubscibe(careerManagerId) {
    console.log(this.employeeReviewUrl.getByParamUrl(
      { "careerManagerId": careerManagerId }
    ))
    return this.restApiService.get(
      this.employeeReviewUrl.getByParamUrl(
        { "careerManagerId": careerManagerId }
      )
    )
  }

  getEmployeeReviewByCareerManagerId(careerManagerId) {
   
    this.restApiService.get(
      this.employeeReviewUrl.getByParamUrl(
        { "careerManagerId": careerManagerId }
      )
    ).subscribe(res => {
      this.setEmployeeReview(res.body)
      console.log( this.employeeReviewList )

    })
  }

  setEmployeeReview(employeeReviews) {
    this.employeeReviewList = [];
    for (let employeeReview of employeeReviews) {
      this.employeeReviewList.push(new EmployeeReview(employeeReview))
    }
  }

  updateEmployeeReview(payload){
    this.restApiService.put(
      this.employeeReviewUrl.getBaseUrl(),
      payload
    ).subscribe()
  }

  updateEmployeeReviewSubscribe(payload){
    return this.restApiService.put(
      this.employeeReviewUrl.getBaseUrl(),
      payload
    )
  }

}
