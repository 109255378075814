export enum jobTitles{
    'Consultant', 
    'Sr. Consultant',
    'Manager',
    'Sr. Manager',
    'Analyst',
    'Director',
}

export enum AuthoritiesConstants {

    ADMIN = "ROLE_ADMIN",
    USER = "ROLE_USER",
    ANONYMOUS = "ROLE_ANONYMOUS",
    PROJECT_MANAGER = "PROJECT_MANAGER",
    PROJECT_SUPERVISOR = "PROJECT_SUPERVISOR",
    CAREER_MANAGER = "ROLE_CAREER_MANAGER"
}