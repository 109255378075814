import { Project } from "../models/project";

export class UserDTO {
    public firstName: String;
    public lastName: String;
    public login: String;
    public email: String;
    public imageUrl: String;
    public activated: Boolean = false;
    public langKey: String;
    public createdBy: String;
    public createdDate: Date;
    public lastModifiedBy: String;
    public lastModifiedDate: String;
    public authorities: string[];
    public jobTitle: String;
    public mobileNumber: String;
    public officeLocation: String;
    public microsoftId: String;
    public project: Project[];
    public id: string;
    public careerManagerId:string;
    public department:string;
    public employeeId:string;
    public dateOfJoining:Date;
    constructor(){
        this.firstName = "";
        this.lastName = "";
        this.login = "";
        this.email = "";
        this.imageUrl = "";
        this.activated = false;
        this.langKey = "";
        this.createdBy = "";
        this.createdDate = null;
        this.lastModifiedBy = "";
        this.lastModifiedDate = "";
        this.authorities = [];
        this.jobTitle = "";
        this.mobileNumber = "";
        this.officeLocation = "";
        this.microsoftId = "";
        this.careerManagerId = "";
        this.dateOfJoining = null;
    }

}