import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserDetailsService {

  public userDetails : any;

  constructor() { }

  getUserDetails() {
    return this.userDetails;
  }

  setUserDetails(userDetails) {
    this.userDetails = userDetails;
  }

  hasRole(roles: string[]) {
    if (this.userDetails != undefined) {
      let authorities: string[] = this.userDetails.authorities;
      for (let index = 0; index < roles.length; index++) {
        if (authorities.includes(roles[index])) {
          return true;
        }
      }
    }
    return false;
  }

  getAllUserDetatils(){
    
  }

}
