import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FormSchemaDTO } from 'src/app/dtos/FormSchemaDTO';
import { Page } from 'src/app/services/feedback/feedback-enum';
import { FormsSchemaService } from 'src/app/services/forms/forms-schema.service';
import { EditAppComponent } from '../../edit-app/edit-app.component';
import { FeedbackGenerationDialogComponent } from '../../feedback-generation/feedback-generation-dialog/feedback-generation-dialog.component';

@Component({
  selector: 'app-main-content-feedback',
  templateUrl: './main-content-feedback.component.html',
  styleUrls: ['./main-content-feedback.component.css']
})
export class MainContentFeedbackComponent implements OnInit {

  constructor(private dialog: MatDialog,
    public formService: FormsSchemaService) { }

  searchText: string;
  public newFormSchema = new FormSchemaDTO();
  formTemplatesModel: any = [];
  public temp: any[];
  public inc: number = 0;
  formDTO :FormSchemaDTO = new FormSchemaDTO();

  ngOnInit(): void {
    this.formService.getFormPagination()
  }

  createFeedbackFormDialog() {
    alert("create feedback form")
  }

  onSearchTextTyped(event) {
    console.log(event)
    if (event == "") {
      event = ""
      this.searchText = ""
      this.formService.forms = [];
      this.formService.getFormPagination();
    } else {
      this.formService.forms = [];
      this.formService.getProjectBySearch(event);
    }
  }

  onAddFormTemplate() {

    this.openDialog();

    this.temp = this.formTemplatesModel;

    this.inc++;
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "95%";
    dialogConfig.height = "90%";
    dialogConfig.data = {
      property: null,
      page: Page.Project
    }
    let dialogRef = this.dialog.open(EditAppComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {

      if (result != undefined) {
        this.newFormSchema.formTemplate = result;
        this.formTemplatesModel.push(result);
        // here result contains data for one form with multiple sections
        this.saveForm(result)
      }
    });
  }

  saveForm(feedbackFormInfo) {

    feedbackFormInfo.formTemplate = JSON.stringify(feedbackFormInfo);
    feedbackFormInfo.formType = feedbackFormInfo[0].description;
    this.formService.addNewFormSchemaSubscribe(feedbackFormInfo).subscribe(res => {
      this.formService.getFormPagination()
    });


    // for (let index = 0; index < this.formTemplatesModel.length; index++) {
    //   feedbackFormInfo.formTemplate = JSON.stringify(this.formTemplatesModel[index]);
    //   feedbackFormInfo.formType = this.formTemplatesModel[index][0].description;

    //   this.formService.addNewFormSchemaSubscribe(feedbackFormInfo).subscribe(res => {
    //     this.formService.getFormPagination()
    //   });
    // }

    // this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Project created successfully' });
    // this.router.navigate(['all-projects']);
  }

  OnPageClick(event) {
    this.formService.pageCount = event.pageIndex
    this.formService.pageCount += 1
    this.formService.getFormPagination();
  }

}
