<div class="container-fluid subGoalMainContainer p-3">
    <div class="row pt-2">
        <div class="col">

            <!-- Goal goal adding -->
            <div class="card shadow">
                <button *ngIf="this.activityType == 'Edit'" mat-button style="text-align: unset;"
                    (click)="editSubGoalProgress(this.goal.goalType)">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-1">
                                <img class="img-style"
                                    [src]="this.initialAvatarService.getAvatar()">
                            </div>
                            <div class="col-5">
                                <p class="h5">{{this.goal.title}}</p>

                                <p>
                                    <small class="text-muted">
                                        {{this.name}}</small>
                                </p>
                            </div>
                            <div class="col-2"></div>
                            <div class="col-4">
                                <app-progress-bar [progressValue]="this.goal.currentValue" [metric]="this.goal.metric"
                                    [lastUpdated]="this.goal.lastUpdated" [targetValue]="this.goal.targetValue"
                                    [goalStatus]="this.goal.goalStatus"></app-progress-bar>
                            </div>
                        </div>
                    </div>
                </button>

                <!-- For goal Viewing -->
                <div class="card-body" *ngIf="this.activityType != 'Edit'">
                    <div class="row">
                        <div class="col-1">
                            <img class="img-style" [src]="this.initialAvatarService.getInitialImage('Tejas','Kuthe')">
                        </div>
                        <div class="col-5">
                            <p class="h5">{{this.goal.title}}</p>

                            <p>
                                <small class="text-muted">
                                    {{this.name}}</small>
                            </p>
                        </div>
                        <div class="col-2"></div>
                        <div class="col-4">
                            <app-progress-bar [progressValue]="this.goal.currentValue" [metric]="this.goal.metric"
                                [lastUpdated]="this.goal.lastUpdated" [targetValue]="this.goal.targetValue"
                                [goalStatus]="this.goal.goalStatus"></app-progress-bar>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>


</div>