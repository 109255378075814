<div style="position: relative; height: 30px;">
    <button mat-icon-button (click)="onClose()" style="position: absolute;z-index: 1;  top: 5px; right: 5px;">
        <mat-icon class="closebtn">close</mat-icon>
    </button>
</div>

<div style="position: relative; height: 30px;">
    <button mat-mini-fab (click)="addFeedbackGeneration()"
        style="position: absolute;z-index: 1;  top:20px; right: 25px;">
        <mat-icon>add</mat-icon>
    </button>
</div>

<form [formGroup]="multipleFeedbackGenerationArray" (ngSubmit)="onSubmit()">


    <div class="container formStyle bg-light">

        <div class="row shadow-lg mt-5 bg-white rounded" formArrayName="feedbacks"
            *ngFor="let feedback of this.feedbacks().controls; let i = index">

            <div class="col-10">
                <div class="row">
                    <div class="col" [formGroupName]="i">

                        <mat-form-field appearance="standard">
                            <mat-label>Select a Form</mat-label>
                            <mat-select formControlName="formTemplate">
                                <mat-option *ngFor="let form of this.formService.forms" [value]="form">{{form.formType}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>

                    <div class="col" [formGroupName]="i">

                        <mat-form-field appearance="standard">
                            <mat-label>Select feedback type</mat-label>
                            <mat-select formControlName="feedbackType">
                                <mat-option *ngFor="let form of this.feedbackTypeEnum" [value]="form">{{form}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>


                
                    <div class="col" [formGroupName]="i">

                        <mat-form-field appearance="standard">
                            <mat-label>Select Direct Reportee</mat-label>
                            <mat-select formControlName="directReportee">
                                <mat-option *ngFor="let user of this.userService.directReportees" [value]="user">
                                    {{processName(user)}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>

                    <div class="col" [formGroupName]="i">

                        <mat-form-field appearance="standard">
                            <mat-label>Select Company employee</mat-label>
                            <mat-select formControlName="employees">
                                <mat-option *ngFor="let user of this.userService.usersDto" [value]="user">
                                    {{processName(user)}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>
                </div>
            </div>
            <div class="col-2">
                <button type="button" mat-icon-button style="margin-top: 15px; margin-right: 5px;float: right;"
                    class="btn btn-success btn small" (click)="panelH._toggle()">
                    <mat-icon aria-hidden="false" aria-label="Preview form">expand_more</mat-icon>
                </button>

                <button type="button" mat-mini-fab color="warn"
                    style="margin-top: 15px; margin-right: 5px;float: right;" (click)="removeFeedback(i)"><i
                        class=" fas fa-minus"></i></button>
            </div>

        </div>

    </div>

    <div class="formStyle mt-4">
        <button mat-raised-button style="background-color: #3e3e3e; color: white;" type="submit">Submit</button>
    </div>
</form>