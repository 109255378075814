import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ProjectDTO } from 'src/app/dtos/ProjectDTO';
import { Project } from 'src/app/models/project';
import { ClientService } from 'src/app/services/client/client.service';
import { ImageService } from 'src/app/services/Images/image.service';
import { ProjectService } from 'src/app/services/project.service';
import { AddProjectCardComponent } from '../add-project-card/add-project-card.component';

@Component({
  selector: 'app-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.css']
})
export class ProjectCardComponent implements OnInit {

  constructor(public projectService:ProjectService,
              public clientService:ClientService,
              public imageService:ImageService,
              private dialog: MatDialog,
              private router:Router) { }

  projectData = new ProjectDTO();
  projectLogo;

  ngOnInit(): void {
    this.projectService.getProjectPagination()
    
  }

  onEdit(project){
    // alert(JSON.stringify(project))

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "70%";
    dialogConfig.height = "60%";
    dialogConfig.data = project;
    const dialogRef = this.dialog.open(AddProjectCardComponent, dialogConfig);
  
    dialogRef.afterClosed().subscribe(result => {
    });
    
  }

  onViewProject(project){
    this.router.navigate(['projectDetailView',project.id]);
  }
 
  deleteProject(id){
    this.projectService.delete(id);
  }

  processLogo(project){
    this.clientService.getClientByIdSubscribe(project.clientId).subscribe(res=>{
      return res.body.imageId?this.setProjectLogo(res.body.imageId):"assets/download.png"
    })
  }

  setProjectLogo(imageId) {
    return this.imageService.getImage(imageId)
  }

}
