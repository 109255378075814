<div class="row functionalArea">
    <div class="col-2 blank"></div>

    <div class="col-8 align-item-center">
        <div class="wrapper">
            <div class="searchBar">
                <input id="searchQueryInput" type="text" name="searchQueryInput"  (ngModelChange)="onSearchTextTyped($event)" [ngModel] = "searchText" placeholder="Search Employee"
                    value=""/>
                <button id="searchQuerySubmit" type="submit" name="searchQuerySubmit">
                    <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                        <path fill="#666666"
                            d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
                    </svg>
                </button>
            </div>
        </div>
    </div>

    <div class="col-2 blank text-center addButtonCol">               
         <button mat-flat-button class="addButton" (click)=addEmployeeDialog()><mat-icon class="alignExpand whiteIcon" aria-hidden="false" aria-label="Example home icon">person_add</mat-icon></button>
         <p class="h6" style="margin-top: 5px;">Add Employee</p>
    </div>

</div>
<div class="row displayArea">
    <div class="col cardContent">
        <app-employee-card *ngFor = "let emp of this.employeeServie.usersDto" [empDetail] = emp [initialAvatar]="this.initialAvatarService.getInitialImage(this.empDetail.firstName,this.empDetail.lastName)"></app-employee-card>
    </div>
</div>