<div fxFlex="row" fxLayoutAlign="center center" fxLayoutGap="10px">
    <mat-toolbar class="header">
        <mat-toolbar-row class="toolbar-row">

            <!-- <div *ngIf="this.userDetail.getUserDetails()">
                <button mat-icon-button (click)="sidenav.toggle();">
                    <mat-icon color="primary">menu</mat-icon>
                </button>
            </div> -->
            <span><img src="assets/fg-logo-full.png" alt="" style="width: 135px" class="pl-4"></span>
            <!-- <span>Performance Feedback Management</span> -->
            <span class="spacer"></span>
            <span class="example-spacer"></span>
            <button matTooltip="Logout" *ngIf="this.userDetail.getUserDetails()" mat-icon-button  (click)="this.authGuardService.signOut()" style="position: absolute;z-index: 1;  top: 15px; right: 15px; color: black;"><mat-icon>power_settings_new</mat-icon></button>

            
        </mat-toolbar-row>
    </mat-toolbar>
</div>