import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { User } from 'src/app/models/user';
import { Activity } from 'src/app/services/activity/activity-enum';
import { EmployeeReviewService } from 'src/app/services/employeeReview/employee-review.service';
import { ReviewStatus } from 'src/app/services/employeeReview/employeeReview-enum';
import { FeedbackService } from 'src/app/services/feedback.service';
import { InitialAvatarService } from 'src/app/services/initialAvatar/initial-avatar.service';
import { OneOnOneMeetingService } from 'src/app/services/oneOnOneMeeting/one-on-one-meeting.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';
import { AddEmployeeCardComponent } from '../../employee/add-employee-card/add-employee-card.component';
import { MainContentComponent } from '../../employee/main-content/main-content.component';
import { EvaluationConfigurationComponent } from '../../evaluation-configuration/evaluation-configuration.component';
import { OneOnOneMeetingComponent } from '../../one-on-one-meeting/one-on-one-meeting/one-on-one-meeting.component';
import { GoalSheetViewEmployeeComponent } from '../goal-sheet/goal-sheet-view-employee/goal-sheet-view-employee.component';
import { GoalSheetComponent } from '../goal-sheet/goal-sheet.component';

@Component({
  selector: 'app-direct-reportees-cm',
  templateUrl: './direct-reportees-cm.component.html',
  styleUrls: ['./direct-reportees-cm.component.css']
})
export class DirectReporteesCMComponent implements OnInit {
  public employeeReviewCompleted: boolean = false;
  constructor(public userService: UserService,
    public userDetailService: UserDetailsService,
    private dialog: MatDialog,
    public initialAvatarService: InitialAvatarService,
    public employeeReviewService: EmployeeReviewService,
    public oneOnOneMeetingService: OneOnOneMeetingService
  ) { }

  ngOnInit(): void {
    // this.userService.getAllDirectReportees(this.userDetailService.getUserDetails().id);
    this.employeeReviewService.getEmployeeReviewByCareerManagerIdSubscibe(this.userDetailService.getUserDetails().id).subscribe(res => {
      this.employeeReviewService.setEmployeeReview(res.body)

      console.log(res.body[0].reviewStatus == ReviewStatus.COMPLETED)
      for (let employeeReview of this.employeeReviewService.employeeReviewList)
        console.log(employeeReview.employee)
    })



  }



  processDesignation(user) {
    let designation;
    if (user && user.firstName)
      return designation = user.jobTitle
  }


  evaluationConfiguration(user) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = user
    // dialogConfig.data = this.project;
    const dialogRef = this.dialog.open(EvaluationConfigurationComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  oneOnOneMeeting(employeeReview) {
    let careerManager: User;
    this.userService.getUserByID(employeeReview.careerManagerId).subscribe(res => {
      careerManager = new User(res.body)
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.width = "70%";
      dialogConfig.height = "80%";
      dialogConfig.data = employeeReview
      dialogConfig.data = {
        employeeReview: employeeReview,
        careerManager: careerManager,
        disableIfCompleted: employeeReview.reviewStatus == ReviewStatus.COMPLETED ? true : false
      }
      // dialogConfig.data = this.project;
      const dialogRef = this.dialog.open(OneOnOneMeetingComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
      });
    })



    
  }

  viewGoalSheet(employee) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "70%";
    dialogConfig.height = "85%";
    dialogConfig.data = {
      employee: employee,
      year: new Date().getFullYear(),
      activityType: Activity.VIEW
    }
    // dialogConfig.data = this.project;
    const dialogRef = this.dialog.open(GoalSheetViewEmployeeComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
