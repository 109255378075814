import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from 'src/app/models/user';
import { InitialAvatarService } from 'src/app/services/initialAvatar/initial-avatar.service';
import { UserService } from 'src/app/services/user/user.service';
import { MainContentProjectComponent } from '../../project/main-content-project/main-content-project.component';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { OneOnOneMeetingService } from 'src/app/services/oneOnOneMeeting/one-on-one-meeting.service';
import { OneOnOneMeetingDto } from 'src/app/dtos/oneOnOneMeetingDTO';
import { EmployeeReview } from 'src/app/models/employeeReview';
import { ReviewStatus } from 'src/app/services/employeeReview/employeeReview-enum';
import { EmployeeReviewService } from 'src/app/services/employeeReview/employee-review.service';
import { EmployeeReviewDTO } from 'src/app/dtos/EmployeeReviewDTO';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { NotificationService } from 'src/app/services/notification/notification.service';

@Component({
  selector: 'app-one-on-one-meeting',
  templateUrl: './one-on-one-meeting.component.html',
  styleUrls: ['./one-on-one-meeting.component.css']
})
export class OneOnOneMeetingComponent implements OnInit {

  oneOneOneMeetingForm: FormGroup;

  constructor(public employeeReviewService: EmployeeReviewService,
    public oneOnOneService: OneOnOneMeetingService, public userService: UserService,
    public fb: FormBuilder,
    private spinnerService:SpinnerService,
    private notificatonService:NotificationService,
    private userDetailService:UserDetailsService,
    public initialAvatarService: InitialAvatarService,
    public dialogRef: MatDialogRef<MainContentProjectComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {
    this.oneOneOneMeetingForm = this.fb.group({
      meetingSummary: ['', [Validators.required]],
      meetingDate: [new Date(), [Validators.required]],

    })

    if (this.data.disableIfCompleted) {
      this.oneOnOneService.getOneOnOneMeetingByIdSubscribe(this.data.employeeReview.oneOnOneMeetingId).subscribe(res => {
        this.oneOneOneMeetingForm.get('meetingDate').setValue(res.body.meetingDate)
        this.oneOneOneMeetingForm.get('meetingDate').disable({onlySelf:true})
        this.oneOneOneMeetingForm.get('meetingSummary').setValue(res.body.meetingSummary)
        this.oneOneOneMeetingForm.get('meetingSummary').disable({onlySelf:true})

      })
    }
  }

  public careerManager: User;
  ngOnInit(): void {


  }

  closeDialog() {
    console.log("close clicked")
    this.dialogRef.close()
  }

  onSubmit() {
    if (this.oneOneOneMeetingForm.valid) {
      console.log(this.oneOneOneMeetingForm)

      let oneOnOneMeetingDto: OneOnOneMeetingDto = new OneOnOneMeetingDto()

      oneOnOneMeetingDto.careerManagerId = this.data.employeeReview.careerManagerId
      oneOnOneMeetingDto.directReporteeId = this.data.employeeReview.employeeId
      oneOnOneMeetingDto.meetingDate = this.oneOneOneMeetingForm.get('meetingDate').value
      oneOnOneMeetingDto.meetingSummary = this.oneOneOneMeetingForm.get('meetingSummary').value

      this.oneOnOneService.postOneOnOneMeetingSubcribe(oneOnOneMeetingDto).subscribe(res => {

        let employeeReviewDto = new EmployeeReviewDTO()
        employeeReviewDto.careerManagerId = this.data.employeeReview.careerManagerId
        employeeReviewDto.dueDate = this.data.employeeReview.dueDate
        employeeReviewDto.employeeId = this.data.employeeReview.employeeId
        employeeReviewDto.id = this.data.employeeReview.id
        employeeReviewDto.oneOnOneMeetingId = res.body.id
        employeeReviewDto.reviewCycle = this.data.employeeReview.reviewCycle
        employeeReviewDto.reviewStatus = ReviewStatus.COMPLETED
        employeeReviewDto.reviewType = this.data.employeeReview.reviewType

        this.employeeReviewService.updateEmployeeReviewSubscribe(employeeReviewDto).subscribe(res=>{
          this.employeeReviewService.getEmployeeReviewByCareerManagerIdSubscibe(this.userDetailService.getUserDetails().id).subscribe(res => {
            this.employeeReviewService.setEmployeeReview(res.body)
            this.spinnerService.requestEnded()
            this.notificatonService.sucess("Employee review completed")
          })
        })

        

        this.dialogRef.close()
      })

    }else{
      this.notificatonService.invalidFormData()
    }
  }
}
