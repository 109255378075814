import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EditAppComponent } from 'src/app/components/edit-app/edit-app.component';
import { FormTemplateDialogComponent } from 'src/app/components/feedback-submission/form-template-dialog/form-template-dialog/form-template-dialog.component';
import { FormSchemaDTO } from 'src/app/dtos/FormSchemaDTO';
import { FeedbackSubmitStatus } from 'src/app/services/feedback/feedback-enum';
import { FormsSchemaService } from 'src/app/services/forms/forms-schema.service';

@Component({
  selector: 'app-feedback-form-card-shared',
  templateUrl: './feedback-form-card-shared.component.html',
  styleUrls: ['./feedback-form-card-shared.component.css']
})
export class FeedbackFormCardSharedComponent implements OnInit {
  
  @Input() feedbackForm;
  public formSectionCount;
  private formTemplateObj;
  private feedbackFormDTO = new FormSchemaDTO();

  constructor(public formService:FormsSchemaService,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    // console.log(this.feedbackForm)
    // if(this.feedbackForm){
    //   this.formTemplateObj = JSON.parse(this.feedbackForm.formTemplate);
    //   //this.countFormSection()
    // }
  }

  countFormSection(formTemplate){
  //  return JSON.parse(this.formTemplate).length + " Section"
  //  alert(this.formSectionCount)
  }

 openEditDialog(feedback): void {
    const dialogRef = this.dialog.open(EditAppComponent, {
      width: "95%",
      height: "90%",
      data: {
        property:JSON.parse(feedback.formTemplate)
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
     // alert(result)

      if (result != undefined && feedback.id != undefined) {
        this.feedbackFormDTO.id = feedback.id;
        this.feedbackFormDTO.formTemplate = JSON.stringify(result)
        this.feedbackFormDTO.formType = result[0].description
        this.formService.updateFeedbackForm(this.feedbackFormDTO);
      }
      
    });
  }


  openFormTemplateDialog(feedbackForm): void {
    if (feedbackForm != undefined) {
      const dialogRef = this.dialog.open(FormTemplateDialogComponent, {
        // width: "95%",
        // height: "90%",
        data: {
                property:feedbackForm.formTemplate, 
                disableEdit:true
              },
        // data: property,
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(result => {
        // if (result != undefined) {
        //   property.formSubmissionAnswer = JSON.stringify(result);
        //   property.FeedbackReadStatus = FeedbackReadStatus.READ
        //   property.feedbackSubmitStatus = FeedbackSubmitStatus.SUBMITTED
        //   property.updatedDateTime = new Date()
        //   property.updatedById = this.userDetailService.getUserDetails().id
        //   this.feedbackDTO = property
        //   this.feedbackDTO.userReviewToId = property.userTo.id
        //   this.feedbackDTO.userReviewToId = property.userFrom.id
         
        //   if(property.project!=null)
        //     this.feedbackDTO.projectId = property.project.id
        //   else if(property.careerManagerTeam != null)
        //     this.feedbackDTO.careerManagerTeamId = property.careerManagerTeam.id
         
        //     this.feedbackService.updateFeedbackSubscribe(this.feedbackDTO).subscribe(res => {
        //     this.feedbackService.getFeedbackByUserIdAndStatus(this.userDetailService.getUserDetails().id,FeedbackReadStatus.UNREAD,FeedbackSubmitStatus.PENDING).subscribe(res=>{
        //      this.showFeedbackList = res.body
        //     })
        //   })
        // }
      });

    }
  }


  deleteFeedbackForm(feedbackForm){
    this.formService.deleteFeedbackForm(feedbackForm.id)
  }
}
