<div style="position: relative;">
    <button mat-icon-button (click)="closeDialog()" style="position: absolute;z-index: 1;  top: 15px; right: 15px;">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div class="container-fluid formStyle">



    <form [formGroup]="newEmployeeDetails" (ngSubmit)="onSubmit()">
        <div class="row">
            <div class="col"> <img class="img-style" src="assets/download.png"> </div>
        </div>
        <div class="row">
            <div class="col form-group">
                <mat-form-field appearance="standard">
                    <mat-label>First Name</mat-label>
                    <input matInput formControlName="firstName" autocomplete="off">
                </mat-form-field>


            </div>

            <div class="col form-group">
                <mat-form-field appearance="standard">
                    <mat-label>Last Name</mat-label>
                    <input matInput formControlName="lastName" autocomplete="off">
                </mat-form-field>
            </div>

            <div class="col form-group">
                <mat-form-field appearance="standard">
                    <mat-label>Employee Id</mat-label>
                    <input matInput formControlName="employeeId" autocomplete="off">
                </mat-form-field>
            </div>
        </div>

        <div class="row">


            <div class="col form-group">
                <mat-form-field appearance="standard">
                    <mat-label>Department</mat-label>
                    <mat-select formControlName="department">
                        <mat-option value="API Management & Integration">API Management & Integration</mat-option>
                        <mat-option value="Data Management">Data Management</mat-option>
                        <mat-option value="Client Success">Client Success</mat-option>
                        <mat-option value="Data science & analytics">Data science & analytics</mat-option>
                        <mat-option value="Support funtion">Support funtion</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col form-group">
                <mat-form-field appearance="standard">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email" autocomplete="off">
                </mat-form-field>
            </div>
            <div class="col form-group">
                <mat-form-field appearance="standard">
                    <mat-label>Mobile Number</mat-label>
                    <input matInput formControlName="mobileNo" autocomplete="off">
                </mat-form-field>
            </div>
        </div>

        <div class="row">

            <div class="col form-group">
                <!-- <mat-form-field appearance="standard">
                <mat-label>Job title</mat-label>
                <input matInput formControlName="jobTitle">
            </mat-form-field> -->
                <mat-form-field appearance="standard">
                    <mat-label>Job title</mat-label>
                    <mat-select formControlName="jobTitle">
                        <mat-option *ngFor="let jobTitle of this.jobTitles" [value]="jobTitle">{{jobTitle}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <!-- <div class="col form-group">
            <mat-form-field appearance="standard">
                <mat-label>Project</mat-label>
                <mat-select formControlName="project">
                    <mat-option *ngFor="let project of this.projectService.projects" [value]="project">{{project.projectName}}</mat-option>
                    
                </mat-select>
            </mat-form-field>
        </div> -->

            <div class="col form-group">
                <!-- <mat-form-field appearance="standard">
                <mat-label>Career Manager</mat-label>
                <mat-select formControlName="careerManager">
                    <mat-option *ngFor="let user of this.userService.users" [value]="user">{{user.firstName + ' '+user.lastName}}</mat-option>
                    
                </mat-select>
            </mat-form-field> -->

                <mat-form-field appearance="standard">
                    <mat-label>Career Manager</mat-label>
                    <input matInput [matAutocomplete]="careerManagerAuto" formControlName="careerManager">
                    <mat-autocomplete #careerManagerAuto="matAutocomplete" [displayWith]="displayFnCareerManager">
                        <mat-option *ngFor="let manager of filteredCareerManager | async" [value]="manager">
                            <span style="margin-left: 10px;">{{ manager.firstName + ' ' + manager.lastName
                                }}</span>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

            </div>
            <div class="col form-group">

                <mat-form-field appearance="standard">
                    <mat-label>Date of joining</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="dateOfJoining">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>

            </div>
        </div>
        <div>
            <button mat-raised-button style="background-color: #3e3e3e; color: white;" type="submit">Save</button>
        </div>
    </form>
</div>