<div class="row contentRow">
    <div class="col contentCol">
        <div class="row justify-content-end p-5">
            <button mat-flat-button (click)="openGenerateDialog()">Generate Feedback</button>
        </div>
        <div class="row p-2" *ngFor="let feedback of this.feedbackService.feedbacks">
            <div class="container-fluid cardContent">
                <div class="row">
                    <div class="col-1 col-sm-1 justify-content-center"><img class="img-style" [src]="this.initialAvatarService.getInitialImage(feedback.userReviewTo.firstName,feedback.userReviewTo.lastName)"></div>
                    <div class="col-3">
                        <div class="alignContent"></div>
                        <p class="h3">{{processName(feedback.userReviewTo)}}</p>
                        <p class="h5"><small class="text-muted">{{processDesignation(feedback.userReviewTo)}}</small></p>
                    </div>
            
                    <div class="col-6 col-sm-6 text alignContent justify-content-md-center">
                        <p>Status : <span *ngIf="feedback.feedbackSubmitStatus=='SUBMITTED'" style="color: green;">{{feedback.feedbackSubmitStatus}} </span> <span *ngIf="feedback.feedbackSubmitStatus=='PENDING'" style="color: brown;">{{feedback.feedbackSubmitStatus}} </span></p>
                        <p>Reviewed By : {{processName(feedback.userReviewFrom)}}</p>
                        <p>Generated : 2 Days ago</p>
                    </div>
                    <div class="col-2 alignContent text-center">
                        <button mat-flat-button><mat-icon class="alignExpand" aria-hidden="false" aria-label="Example home icon">email</mat-icon></button>
                        <p class="alignExpandText">Send Reminder</p>
                    </div>
                </div>
            
            </div>
        </div>
        
       

    </div>
</div>